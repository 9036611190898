import React, { createContext, useState, useContext, ReactNode } from "react"

interface User {
  id: string
  profilePicture: string
  bannerPicture: string
  displayName: string
  username: string
  activityBucks: number
  thoughts: Thought[]
  followers: Follower[]
  following: Following[]
  aboutMe: string | null
  bestCourse: string | null
  program: string | null
  verificationStatus: boolean
  institution: {
    name: string
  }
  searchHistory: [{
    value: string
    timestamp: string
  }]
  createdAt: string
}

interface Thought {
  id: string
}

interface Follower {
  id: string
}

interface Following {
  id: string
}

interface GlobalState {
  user: User | null
  loading: boolean
}

interface GlobalStateContextType {
  state: GlobalState
  setUser: (userUpdates: Partial<User> | null) => void
  setLoading: (loading: boolean) => void
}

const GlobalStateContext = createContext<GlobalStateContextType | undefined>(undefined)

export const useGlobalState = (): GlobalStateContextType => {
  const context = useContext(GlobalStateContext)
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider")
  }
  return context
}

interface GlobalStateProviderProps {
  children: ReactNode;
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const [state, setState] = useState<GlobalState>({
    user: null,
    loading: false
  })

  const setUser = (userUpdates: Partial<User> | null) => {
    setState((prevState) => ({
      ...prevState,
      user: userUpdates ? { ...prevState.user!, ...userUpdates } : null,
    }))
  }

  const setLoading = (loading: boolean) => {
    setState((prevState) => ({
      ...prevState,
      loading,
    }));
  }

  return (
    <GlobalStateContext.Provider value={{ state, setUser, setLoading }}>
      {children}
    </GlobalStateContext.Provider>
  )
}