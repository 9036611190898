import { gql } from '@apollo/client'

export const SEARCH_INSTITUTION = gql`
  query SearchInstitutions($query: String!) {
    searchInstitutions(query: $query) {
      id
      name
      __typename
    }
  }
`

export const GET_USER_BY_USERNAME = gql`
  query getUserByUsername($username: String!) {
    getUserByUsername(username: $username) {
      id
      profilePicture
      bannerPicture
      displayName
      username
      activityBucks
      thoughts {
        id
      }
      followers {
        id
        username
        profilePicture
      }
      following {
        id
        username
        profilePicture
      }
      aboutMe
      bestCourse
      program
      verificationStatus
      institution {
        name
      }
      createdAt
    } 
  }
`

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      profilePicture
      bannerPicture
      displayName
      username
      activityBucks
      thoughts {
        id
      }
      followers {
        id
        username
        profilePicture
      }
      following {
        id
        username
        profilePicture
      }
      aboutMe
      bestCourse
      program
      verificationStatus
      institution {
        name
      }
      createdAt
    }
  }
`

export const GET_THOUGHT = gql`
  query GetThought($getThoughtId: ID!) {
    getThought(id: $getThoughtId) {
      id
      author {
        id
        username
        profilePicture
        verificationStatus
      }
      title
      body
      poll
      createdAt
    }
  }
`

export const GET_THOUGHTS = gql`
  query GetThoughts($thoughtIds: [ID!]!) {
    getThoughts(ids: $thoughtIds) {
      id
      author {
        id
        username
        profilePicture
        verificationStatus
      }
      title
      body
      attachments {
        name
        type
        url
      }
      poll
      createdAt
    }
  }
`

export const SEARCH_USER = gql`
  query SearchUsers($query: String!) {
    searchUsers(query: $query) {
      id
      username
      displayName
      profilePicture
      verificationStatus
    }
  }
`

export const GET_SEARCH_HISTORY = gql`
  query GetSearchHistory {
    getSearchHistory {
      id
      user {
        id
        displayName
        username
        profilePicture
        verificationStatus
      }
      value
      timestamp
    }
  }
`