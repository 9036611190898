import React, { useState, useEffect, useRef, useCallback } from 'react'
const { useLocation } = require('@gatsbyjs/reach-router')
import { useQuery, useSubscription } from '@apollo/client'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import Header from './Header'
import Navbar from './Navbar'
import Loading from './Loading'
import Sidebar from './Sidebar'
import NotificationBar from './NotificationBar'
import { GET_CURRENT_USER } from '../graphql/queries'
import { USER_UPDATED } from '../graphql/subscriptions'
import { useGlobalState } from '../contexts/globalStates'

interface LayoutProps {
  loading: boolean
  children: React.ReactNode
  message: string
}

type ChildProps = {
  refetch: () => void
}

declare global {
  interface Navigator {
    standalone?: boolean
  }
}

interface PWATypes {
  deferredPrompt: any
  showPrompt: boolean
  isInstalled: boolean
}

export default function Layout({ loading, children, message }: LayoutProps) {
  const refs = {
    sidebar: useRef<HTMLDivElement>(null),
    hamburgerButton: useRef<HTMLImageElement>(null),
    touchStartX: useRef(0),
    touchEndX: useRef(0)
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [isHeaderVisible, setIsHeaderVisible] = useState(true)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const location = useLocation()
  const { setUser } = useGlobalState()
  const [PWA, setPWA] = useState<PWATypes>({
    deferredPrompt: null,
    showPrompt: false,
    isInstalled: false
  })

  const { data: queryData, error, loading: queryLoading, refetch } = useQuery(GET_CURRENT_USER, {
    onCompleted: () => setIsInitialLoad(false)
  })

  const { data: newUserData, error: newUserError } = useSubscription(USER_UPDATED)

  useEffect(() => {
    if (newUserData) {
      setUser(newUserData.userUpdated)
    }
      else if (queryData) setUser(queryData.getCurrentUser)
    if (error) console.log(error)
      else if (newUserError) console.log(newUserError)
  }, [queryData, error, newUserData, newUserError])

  const handleTouchStart = (e: TouchEvent) => {
    refs.touchStartX.current = e.touches[0].clientX
  }

  const handleTouchMove = (e: TouchEvent) => {
    refs.touchEndX.current = e.touches[0].clientX
  }

  const handleTouchEnd = () => {
    if (refs.touchStartX.current - refs.touchEndX.current > 50) 
      setIsSidebarOpen(false)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (refs.sidebar.current && !refs.sidebar.current.contains(event.target as Node)) {
      setIsSidebarOpen(false)
    }
  }, [])

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSidebarOpen, handleClickOutside])

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchmove', handleTouchMove)
    document.addEventListener('touchend', handleTouchEnd)
    return () => {
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsHeaderVisible(false)
      } else {
        setIsHeaderVisible(true)
      }
      setLastScrollY(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY])

  const updatePWA = (newData: Partial<PWATypes>) => {
    setPWA(prev => ({ ...prev, ...newData, }))
  }

  useEffect(() => {
    const checkInstalled = () => {
      const isStandalone = navigator.standalone || window.matchMedia("(display-mode: standalone)").matches
      updatePWA({isInstalled: isStandalone})
    }

    checkInstalled()

    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault()
      updatePWA({deferredPrompt: event, showPrompt: !PWA.isInstalled})
    }

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt)
    window.addEventListener("appinstalled", () => {
      console.log("App was installed.")
      updatePWA({isInstalled: true, showPrompt: false})
    })

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt)
      window.removeEventListener("appinstalled", () => updatePWA({isInstalled: true}))
    }
  }, [PWA.isInstalled])

  const handleInstallClick = () => {
    if (PWA.deferredPrompt) {
      PWA.deferredPrompt.prompt()
      PWA.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt")
        } else {
          console.log("User dismissed the install prompt")
        }
        updatePWA({deferredPrompt: null, showPrompt: false})
      })
    }
  }

  const disallowedPages = ['/craft/', '/profile/edit/', '/search/']

  const shieldLogo = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "shield-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const image = getImage(shieldLogo.placeholderImage)

  if (isInitialLoad && queryLoading) return (
    <div className='h-dvh w-full flex items-center justify-center'>
      {image && <GatsbyImage image={image} alt="logo" className='max-sm:w-8/12' />}
    </div>
  )

  return (
    <div>
      {!disallowedPages.includes(location.pathname) && (
        <header
          className={`w-full fixed z-10 transition-all duration-300 ${location.pathname !== '/' ? 'bg-transparent' : 'bg-background border-b border-b-borders shadow-lg'} ${isHeaderVisible ? 'top-0' : '-top-16'}`}
        >
          <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} buttonRef={refs.hamburgerButton} />
          {loading && <Loading />}
        </header>
      )}
      <main className={`transition-all duration-300 ${isSidebarOpen || PWA.showPrompt && 'blur-sm'} ${!disallowedPages.includes(location.pathname) && 'pb-12'}`}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement<ChildProps>(child) && typeof child.type !== "string") {
          return React.cloneElement(child as React.ReactElement<ChildProps>, { refetch })
        }
        return child
      })}
      </main>
      {!disallowedPages.includes(location.pathname) && (
        <>
          <nav className="fixed bottom-0 h-12 w-full bg-background border-t border-t-borders px-8">
            <Navbar />
          </nav>
          <div
            id="sidebar"
            ref={refs.sidebar}
            className={`fixed top-0 left-0 w-10/12 h-screen bg-background border-r border-r-borders z-20 transition-transform duration-500 ease-in-out transform ${
              isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
          >
            <Sidebar />
          </div>
        </>
      )}
      {!PWA.isInstalled && PWA.showPrompt && location.pathname !== '/account/verify-email/' && (
        <>
          <div className='w-10/12 h-fit bg-secondary-background fixed inset-0 m-auto rounded-2xl p-5 z-50 flex flex-col gap-4'>
            <p className='text-center'>For a better experiece, intalling an app version of Ju-materials is recommended</p>
            <button onClick={handleInstallClick} className='bg-primary-orange px-5 py-2 rounded-3xl font-semibold self-center'>
              Install App
            </button>
          </div>
          <div className='absolute inset-0 bg-black bg-opacity-50 z-10 transition-all duration-300' onClick={() => updatePWA({showPrompt: false})}/>
        </>
      )}
      <NotificationBar message={message} />
    </div>
  )
}
