import { gql } from '@apollo/client'

export const USER_UPDATED = gql`
  subscription UserUpdated {
    userUpdated {
      id
      profilePicture
      bannerPicture
      displayName
      username
      activityBucks
      thoughts {
        id
      }
      followers {
        id
        username
        profilePicture
      }
      following {
        id
        username
        profilePicture
      }
      aboutMe
      bestCourse
      program
      verificationStatus
      createdAt
    }
  }
`